.header {
  flex-grow: 1;
}

.campaignContainer {
  display: flex;
  flex-direction: column;
  /* width: 75%; */
  align-items: stretch;
  /* align-self: center; */
  justify-content: center;
  text-align: center;
  flex-grow: 4;
  padding: 1%;
}

.expansionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  flex-grow: 4;
  padding: 1%;
}

.expansionOptionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1%;
  margin-top: 10px;
  flex-wrap: wrap;
}

.spacer {
  display: flex;
  flex-grow: 1;
}

.expansionOption {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
}
.button {
  align-self: center;
  width: 50%;
}

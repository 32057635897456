.header1 {
  font-family: "Nobile";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.408px;
  color: #1f6782;
  border: 1.5px solid #f2f0f0;
  text-shadow: 0px 0px 3px rgba(233, 233, 233, 0.75);
}

.header2 {
  font-family: "Nobile";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.408px;
  color: #1f6782;
  /* border: 1.5px solid #f2f0f0; */
  text-shadow: 0px 0px 3px rgba(233, 233, 233, 0.75);
}

.header3 {
  font-family: "Nobile";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #1f6782;
  /* border: 1px solid #f2f0f0; */
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.75);
}

.header4 {
  font-family: "Nobile";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #1f6782;
  /* border: 0.75px solid #f2f0f0; */
  text-shadow: 0px 0px 2px rgba(233, 233, 233, 0.75);
}

.body {
  font-family: "Nobile";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.408px;
  color: #ffffff;
}

.puzzleSquareTile {
  font-family: "Battambang";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 24px;
  /* or 38% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  color: #F2F2F2;
}

body {
  overscroll-behavior: contain;
}

/* .letter-container {
  /* width: inherit; */
/* outline: auto; */
/* height: inherit; */
/* display: flex; */
/* justify-content: center;
  align-items: center; */
/* grid-column: 1;
  grid-row: 1;
} */

.letter-piece {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.connect {
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
}

.left-piece {
  left: 90%;
}

.right-piece {
  right: 100%;
}

.up-piece {
  top: 60%;
}

.down-piece {
  bottom: 60%;
}

.tile img {
  width: 30%;
}

.arrow {
  width: 30%;
}

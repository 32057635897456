#app {
  display: grid;
  place-content: center;
  background-color: rgb(233, 223, 223);
  height: calc(100vh - 20%);
}

#stat {
  display: grid;
  place-content: center;
  background-color: rgb(233, 223, 223);
  height: 30vh;
}

@import url("https://fonts.googleapis.com/css2?family=Battambang&display=swap");

* {
  font-family: "Battambang", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
